import React from 'react';
import { NavLink, Link } from "react-router-dom";
import logo from '../../assets/images/logo.svg';
import questionIcon from '../../assets/icons/sprites.svg';
import './header.scss'

const Header = () => {
    return (
        <header className='header'>
            <div className='container'>
                <div className='wrapper d-flex justify-content-between align-items-center'>
                    <div className='header-logo'>
                        <Link to='/'><img src={logo} alt='logotype games and soft' /></Link>
                    </div>
                    <nav className='header-nav'>
                        <ul className='header-list d-flex'>
                            <li className='header-list__item'><NavLink to='/' className={({ isActive, isPending }) =>
                                [
                                    "header-list__link",
                                    isActive ? "header-list__link--active" : ""
                                ].join(" ")
                            }>Пополнить стим</NavLink></li>
                            <li className='header-list__item'><NavLink to='/support' className={({ isActive, isPending }) =>
                                [
                                    "header-list__link",
                                    isActive ? "header-list__link--active" : ""
                                ].join(" ")
                            }>Написать в поддержку
                                <svg className="question-icon">
                                    <use href={`${questionIcon}#question-icon`}></use>
                                </svg></NavLink></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    );
};

export default Header;