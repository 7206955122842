import './App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import NoPage from './pages/NoPage';
import Support from './pages/Support';
import Success from './pages/Success';
import Catalog from './pages/Catalog';
import Product from './pages/Product';
import AdminAddgame from './pages/admin/Addgame';
import AdminAllgames from './pages/admin/Allgames';
import Admins from './pages/admin/Admins';
import AdminLogin from './pages/admin/Login';
import UpdateGame from './pages/admin/UpdateGame';
import { AdminAuthContext } from './context/context';
import React, { useState, useEffect } from 'react';
import Construction from './pages/Construction';
function App() {
  const [isAdminAuth, setIsAdminAuth] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('isAuth') === 'true') {
      return setIsAdminAuth(true)
    }

  }, [isAdminAuth])

  return (
    <AdminAuthContext.Provider value={{ isAdminAuth, setIsAdminAuth }}>
      <BrowserRouter>
        <Routes>
          <Route element={<Home />} path='/' />
          <Route element={<Home />} path='/:urlPromo' />
          <Route element={<Support />} path='/support' />
          <Route element={<Success />} path='/success' />
          {/* <Route element={<Catalog />} path='/catalog' />
          <Route element={<Product />} path='/catalog/:name' /> */}
          <Route element={<Construction />} path='/bonus' />
          {/* <Route element={<Construction />} path='/catalog/:name' /> */}
          <Route element={<NoPage />} path='*' />
          {/* <Route path='/secret/login' element={<AdminLogin />} />
          {isAdminAuth
            ? <>
              <Route path='/secret/addgame' element={<AdminAddgame />} />
              <Route path='/secret/allgames' element={<AdminAllgames />} />
              <Route path='/secret/admins' element={<Admins />} />
              <Route path='/secret/updategame/:id' element={<UpdateGame />} />
            </>
            : <Route path='/secret/*' element={<AdminLogin />} />
          } */}
        </Routes>
      </BrowserRouter >
    </AdminAuthContext.Provider>
  );
}

export default App;