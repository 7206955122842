import '../assets/styles/styles.scss';
import '../assets/styles/admin/styles.scss';
import Header from "./header/Header";
import Footer from "./footer/Footer";
import React from 'react';

const Layout = ({ children }) => {
	return (
		<>
			<Header />
			<main>{children}</main>
			<Footer />
		</>
	)
}

export default Layout;