import React, {useState} from "react"

const DropdownInfo = ({title, text}) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div className={`faq-item ${isOpen && 'faq-item--active'}`} onClick={() => setIsOpen(!isOpen)}>
            <div className="faq-item__link">
                <span>{title}</span>
                <i className="faq-item__link--btn"></i>
            </div>
            <div className="faq-item__content">
                <p>{text}</p>
            </div>

        </div>
    )
}

export default DropdownInfo