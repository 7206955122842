import React, { useState } from 'react';
import './input.scss';

function Input({ type, id, placeholder, name, value, register, errors, message, regex, className, required, onChange, check, changeHundler }) {
	const [checked, setChecked] = useState(check);
	const checkChangeHandler = (event) => {
		setChecked(!checked)
		if(changeHundler) {
			return changeHundler(event)
		}
	};
	return (
		<>
			{type === 'checkbox'

				? <input type={type} className={className} value={value} id={id} name={name} placeholder={placeholder} required={required} checked={checked} onChange={checkChangeHandler} />
				: <div className='form-input__wrapper'>
					<input {...register(name, {
						required: message,
						pattern: {
							value: regex,
							message: message,
						}
					})} onChange={onChange} type={type} id={id} name={name} placeholder={placeholder} className={errors[name] ? `${className} input--error` : className} />
					{errors[name] ? <p className='form-group__error'>{errors[name].message}</p> : <></>}
				</div>
			}
		</>
	)
}

export default Input
