import React from 'react';
import './preloader.scss';

const Preloader = ({msg}) => {
    return (
        <div className='preloader'>
            <div className='preloader-text'>{msg}</div>
        </div>
    )
}

export default Preloader;