import React from "react";
import './modal.scss';

const Modal = ({active, setActive, children, notBg, size}) => {
    return (
        <div className={active ? 'b-modal b-modal--active' : 'b-modal'} onClick={() => setActive(false)}>
            <div className={`${active && 'b-modal-content--active'} b-modal-content  ${notBg && 'not-bg'} ${size && 'size'} b-modal-content`} onClick={e => e.stopPropagation()}>
                {children}
            </div>
        </div>
    )
}

export default Modal;