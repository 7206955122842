import React from 'react';
import './button.scss';

function Button({ children, className, type, click }) {
	return (
		<button className={className} type={type} onClick={click}>{children}</button>
	)
}

export default Button
