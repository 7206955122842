import React from 'react';
import dino from '../assets/icons/dino-icon.svg';
import sky from '../assets/icons/sky-icon.svg';
import nopageBg from '../assets/images/nopage-bg.svg';


function NoPage() {
	return (
		<div className='nopage'>
			<div className='container'>
				<div className='nopage__sky'>
					<img src={sky} alt='Облако' />
				</div>
			</div>
			<div className='nopage-content'>
				<div className='nopage-content__bg'>
					<img src={nopageBg} />
				</div>
				<div className='container'>
					<div className='wrapper d-flex align-items-center justify-content-center'>
						<div className='nopage-content__text'>
							4
						</div>
						<div className='nopage-content__img'>
							<img src={dino} />
						</div>
						<div className='nopage-content__text'>
							4
						</div>
					</div>
				</div>
			</div>
			<div className='nopage__text'>
				<div className='container'>
					<h1>Упс! Произошла ошибка</h1>
				</div>
			</div>
		</div>
	)
}

export default NoPage
