import React, { useState } from "react";
import env from "react-dotenv";
import Modal from "../components/modal/Modal";
import goodCheck from '../assets/icons/checkmark-circle.svg';
import errorIcon from '../assets/icons/error-icon.svg';
import closeIcon from '../assets/icons/close-icon.svg';
import Preloader from "../components/preloader/Preloader";
import Button from '../UI/button/button';
import { Link } from "react-router-dom";

const Success = () => {
    let [amountUsd, setAmountUsd] = useState(0);
    const [modalActive, setModalActive] = useState(false);
    const [errorPay, setErrorPay] = useState(false);
    const [loadingPay, setLoadingPay] = useState(true);
    async function sha256(message) {
        // encode as UTF-8
        const msgBuffer = new TextEncoder().encode(message);

        // hash the message
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

        // convert ArrayBuffer to Array
        const hashArray = Array.from(new Uint8Array(hashBuffer));

        // convert bytes to hex string                  
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        return hashHex;
    }
    if (localStorage.getItem('order') && localStorage.getItem('order') !== '') {
        const tinkResCheck = {
            TerminalKey: env.TINK_TOKEN,
            PaymentId: localStorage.getItem('order')
        }
        sha256(`${env.TINK_PASS}${tinkResCheck.PaymentId}${tinkResCheck.TerminalKey}`)
            .then(res => {
                tinkResCheck.Token = res
                fetch(`https://securepay.tinkoff.ru/v2/GetState`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(tinkResCheck)
                }).then(res => res.json()).then(dataTinkGet => {
                    if (dataTinkGet.Status === "CONFIRMED") {
                        const data = {
                            login: localStorage.getItem('login'),
                            email: localStorage.getItem('email'),
                            transaction_id: dataTinkGet.OrderId
                        }
                        fetch('/api/pay/steam', {
                            method: 'POST',
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify(data)
                        }).then(res => res.json()).then(data => {
                            console.log(data)
                            if (data.mySuccess === 'true') {
                                console.log('true')
                                setErrorPay(false)
                                setLoadingPay(false)
                                setModalActive(true)
                                localStorage.removeItem('order')
                                localStorage.removeItem('login')
                                localStorage.removeItem('email')
                            } else if ((data.mySuccess === 'false' && data.dataInterPay.status === -999) || (data.mySuccess === 'false' && data.dataInterPay.status === -100) || (data.mySuccess === 'false' && data.dataInterPay.status === -100)) {
                                fetch(`https://securepay.tinkoff.ru/v2/Cancel`, {
                                    method: 'POST',
                                    headers: {
                                        "Content-Type": "application/json"
                                    },
                                    body: JSON.stringify(tinkResCheck)
                                })
                                console.log(tinkResCheck)
                                setErrorPay(true)
                                setLoadingPay(false)
                                setModalActive(true)
                                localStorage.removeItem('order')
                                localStorage.removeItem('login')
                                localStorage.removeItem('email')
                            } else {
                                setErrorPay(true)
                                setLoadingPay(false)
                                setModalActive(true)
                                localStorage.removeItem('order')
                                localStorage.removeItem('login')
                                localStorage.removeItem('email')
                            }
                        })
                    } else if (dataTinkGet.Status === "FORM_SHOWED" || dataTinkGet.Status === "AUTHORIZATION") {
                        fetch(`https://securepay.tinkoff.ru/v2/Cancel`, {
                            method: 'POST',
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify(tinkResCheck)
                        })
                        setErrorPay(true)
                        setLoadingPay(false)
                        setModalActive(true)
                        localStorage.removeItem('order')
                        localStorage.removeItem('login')
                        localStorage.removeItem('email')
                    }
                })
            })
    }
    return (
        <div className="success">
            <Link to='/'>
            <Modal active={modalActive} setActive={setModalActive} size={true}>
                <div className="b-modal-content__top top">
                    <div className="b-modal-close" onClick={() => setModalActive(false)}><img src={closeIcon} alt="крестик" /></div>
                    <div className="top-image"><img src={errorPay ? errorIcon : goodCheck} alt={errorPay ? 'Галочка' : 'Крестик'} /></div>
                </div>
                <div className="b-modal-content__center center">
                    <h3>{errorPay ? 'Оплата не прошла!' : 'Спасибо!'}</h3>
                    <p>{errorPay ? 'Что-то пошло не так' : 'Оплата прошла успешно!'}</p>
                </div>
                <div className="b-modal-content__bottom bottom">
                    <Link to='/' click={() => setModalActive(false)}  className={errorPay ? 'button button-red' : 'button button-green'}>{errorPay ? 'Повторить попытку' : 'Ок'}</Link>
                </div>
            </Modal>
            </Link>
            <Modal active={loadingPay} setActive={setLoadingPay} notBg={true}>
                <Preloader msg='Ждите' />
            </Modal>
        </div>
    )
}

export default Success;