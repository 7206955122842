import React from 'react';
import Layout from '../components/Layout';
import constructionImg from '../assets/images/construction-img.svg';

const Construction = () => {
    return (
    //    <Layout>
        <div className="construction">
            <div className="container">
                <h1>
                    <p>Страничка</p> <br />
                    находится в разработке
                </h1>
                <h2>Приносим свои извинения. Зайдите пожалуйста позже:)</h2>
            </div>
            <div className="construction__image">
                <img src={constructionImg} alt="В разработке" />
            </div>
        </div>
       // </Layout>
    );
}

export default Construction;
