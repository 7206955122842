import React, { useState } from "react";
import Layout from "../components/Layout";
import Button from '../UI/button/button';
import Input from "../UI/form/Input";
import { useForm } from 'react-hook-form';
import Label from '../UI/form/Label';
import Modal from "../components/modal/Modal";
import goodCheck from '../assets/icons/checkmark-circle.svg';
import errorIcon from '../assets/icons/error-icon.svg';
import closeIcon from '../assets/icons/close-icon.svg';

const Support = () => {
	const [modalActive, setModalActive] = useState(false)
	const [error, setError] = useState(false)
	const { register, handleSubmit, formState: { errors } } = useForm({
		criteriaMode: "all"
	});
	const submitForm = (values, e) => {
		const { message } = e.target
		const data = {
			name: values.name,
			email: values.email,
			message: message.value
		}
		fetch('/api/support', {
			method: 'POST',
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(data)
		}).then(res => {
			if (res.ok) {
				setModalActive(true)
				setError(false)
			} else {
				setModalActive(true)
				setError(true)
			}
		})
	}
	return (
		<Layout>
			<div className='replenishment support'>
				<div className='container'>
					<div className="replenishment-text text-center mb-5">
						<h1>Запрос в поддержку</h1>
					</div>
					<div className="row justify-content-center">
						<div className="col-12 col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
							<form className="replenishment-form form bg-glass" onSubmit={handleSubmit(submitForm)}>
								<div className="form-group">
									<Label id="name" className="label">Имя</Label>
									<Input className="input" register={register} type='text' placeholder='Иван' id='name' name='name' errors={errors} regex={/^[а-яА-ЯёЁa-zA-Z]+$/i} message='Введите имя' />
								</div>
								<div className="form-group">
									<Label id="email" className="label">Электронная почта</Label>
									<Input className="input" register={register} type='email' placeholder='example@gmail.com' id='email' name='email' errors={errors} regex={/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/} message='Введите корректный email' />
								</div>
								<div className="form-group">
									<Label className="label" id="message">Ваше сообщение</Label>
									<textarea className="input" id="message" name="message" style={{ height: 135 }} required={true}></textarea>
								</div>
								<Button className='button button-orange button-orange--active' type='submit'>Отправить обращение</Button>
							</form>
						</div>
					</div>
				</div>
			</div>
			<Modal active={modalActive} setActive={setModalActive}>
				<div className="b-modal-content__top top">
					<div className="b-modal-close"><img src={closeIcon} alt="крестик" /></div>
					<div className="top-image"><img src={error ? errorIcon : goodCheck} alt={error ? 'Галочка' : 'Крестик'} /></div>
				</div>
				<div className="b-modal-content__center center">
					<h3>{error ? 'Ошибка!' : 'Спасибо!'}</h3>
					<p>{error ? 'Ошибка, попробуй заново' : 'Отлично, мы тебе скоро ответим'}</p>
				</div>
				<div className="b-modal-content__bottom bottom">
					<Button click={() => setModalActive(false)} className={error ? 'button button-red' : 'button button-green'}>{error ? 'Повторить попытку' : 'Ок'}</Button>
				</div>
			</Modal>
		</Layout>
	)
}

export default Support;